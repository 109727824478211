/* src/AllProducts.css */

.product-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 100px;
}

.section-title {
    margin-bottom: 20px;
}

.header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header input[type="search"] {
    width: 300px;
    /* Adjust width as needed */
    border-radius: 5px;
    /* Adjust border radius as needed */
    border: 2px solid red;
    /* Red border */
    padding: 5px;
    padding-left: 10px;
    /* Add some padding for better appearance */
}

.nav-tabs .nav-link {
    color: black;
    /* Muted color for inactive tabs */
    border-bottom: none;
    /* Remove underline for inactive tabs */
    transition: color 0.2s, border-bottom 0.2s;
    /* Smooth transition */
}

.nav-tabs .nav-link.active {
    color: #E9272D !important;
    /* Red color for active tab */
    font-weight: bold;
    border-bottom: 3px solid #E9272D !important;
    /* Red underline for active tab */
}

.nav-tabs .nav-link:hover {
    color: #E9272D;
}

.active-tab {
    font-weight: bold !important;
    color: #E9272D !important;
}

.product-cards {
    display: flex;
    justify-content: center;
}

.card {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    background-color: #f7f7f7;
    text-align: center;
    transition: transform 0.2s;
    cursor: pointer;
}

.card:hover {
    transform: scale(1.05);
}

.image-container {
    width: 306px;
    height: 250px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    background-color: white;
}

.card img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    /* Center image */
}

.card-info {
    padding: 10px 0;
    text-align: start;
    padding-left: 20px;
}

.card-icons {
    position: absolute;
    bottom: 10px;

}

.icon {
    margin: 0 10px;
    /* Space between icons */
    font-size: 1.2em;
    transition: color 0.2s;
}

.icon:hover {
    color: #E9272D;
}

@media (max-width: 768px) {
    .header input[type="search"] {
        width: auto;
    }
}