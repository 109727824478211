.shop-container {
    display: flex;
    gap: 20px;
    padding: 20px;
    margin-top: 150px;
}

/* Sidebar (Left Column) */
.shop-sidebar {
    width: 20%;
    padding: 15px;
    border-right: 1px solid #eaeaea;
}

.shop-sidebar h4 {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
}

.shop-sidebar ul {
    list-style-type: none;
    padding-left: 0;
}

.shop-sidebar li {
    margin: 10px 0;
}

.price-filter {
    margin-bottom: 25px;
}

.price-filter input[type="range"] {
    width: 100%;
}

.price-filter button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
}

/* Product Grid (Right Column) */
.product-grid {
    width: 80%;
    padding: 15px;
}

.sort-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.products {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.product-card {
    border: 1px solid #eaeaea;
    padding: 20px;
    text-align: center;
}

.product-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.product-card h3 {
    margin: 10px 0;
    font-size: 18px;
}

.product-card p {
    font-size: 16px;
    color: #555;
}

.customs-shop-card {
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    height: auto;
    display: flex;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
    flex-direction: row;
}

.customs-shop-card:hover {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

/* Styling the image to fit within the card height */
.shop-img {
    width: auto;
    height: 60px;
    object-fit: cover;
}

/* Ensures text is wrapped properly and aligns in the available space */
.product-card-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product-card-desc strong {
    font-size: 16px;
}

.rating {
    margin-bottom: 0px;
}

.card-text {
    font-size: 14px;
    /* Small font to fit in the 72px height */
    margin-bottom: 0;
}

/* Flexbox for aligning the text and button in the same row */
.filter-actions {
    display: flex;
    justify-content: space-between;
    /* Space between the text and button */
    align-items: center;
    /* Vertically align text and button */
    margin-top: 10px;
    /* Add some space from the slider */
}

/* Optional: Styling for the button */
.filter-actions button {
    padding: 5px 15px;
    background-color: #E9272D;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.filter-actions button:hover {
    background-color: #E9272D;
}

.viewMoreButton {
    margin-top: 10px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    background-color: #E9272D !important;
}

@media (max-width: 768px) {
    .shop-container {
        flex-direction: column;
    }

    .shop-sidebar {
        width: 100%;
    }

    .product-grid {
        width: 100%;
    }
}

@media (max-width: 820px) {
    .customs-shop-card {
        flex-direction: column;
        align-items: flex-start;
    }

    .customs-shop-card img {
        width: 50px;
        margin-bottom: 10px;
        object-fit: contain;
    }

    .product-card-desc {
        text-align: left;
    }
}