.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
   margin-top: 150px;
}

.contact-container h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

p {
    color: #666;
}

.contact-content {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.contact-form {
    width: 60%;
}

.contact-form h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: bold;
}

.form-group {
    margin-bottom: 20px;
    text-align: left;
}

.form-group input, .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

textarea {
    resize: none;
}

button.send-message-btn {
    background-color: #E9272D;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.contact-info {
    width: 35%;
    text-align: left;
}

.info-item {
    display: flex;
    align-items: start;
    margin-bottom: 20px;
    flex-direction: column;
}

.info-item img {
    width: 62px;
    margin-right: 10px;
}

.social-icons {
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin-top: 10px;
}

.social-icons a {
    color: #000;
    font-size: 1.5rem;
}


.location {
    margin-top: 40px;
    text-align: center;
}

/* Ensure iframe width is 100% and height adjusts */
.location iframe {
    width: 100%;
    height: 450px;
    border: 0;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .location iframe {
        height: 300px;
    }
}

@media (max-width: 480px) {
    .location iframe {
        height: 200px;
    }
}

@media (max-width: 768px) {
    .contact-content {
        flex-direction: column;
        align-items: center;
    }

    .contact-form, .contact-info {
        width: 100%;
        margin-bottom: 20px;
    }
}