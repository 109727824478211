.main-container {
    overflow-x: hidden;
    margin-top: 50px;
 
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.costume-product-card {
    width: 19rem;
    height: 26rem;
    border-radius: 8px;
    /* margin: 10px; */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2) !important;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.product-image-container {
    position: relative;
    width: 100%;
    height: auto;
    /* fixed height for the image container */
    overflow: hidden;
    
}

.particular-product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.heart-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #d9534f;
    font-size: 1.2rem;
    cursor: pointer;
}

.added-to-cart-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 0.75rem;
    padding: 5px 10px;
    border-radius: 8px;
}

.product-weight {
    font-size: 10px !important;
    color: gray;
    text-align: start;
}

.product-title {
    font-size: 16px !important;
    font-weight: bold;
    margin-top: 10px !important;
    text-align: start;
}

.product-description {
    font-size: 0.5rem;
    color: #6c757d;
    text-align: start;
}

.price-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #28a745;
    margin-right: 10px;
}

.original-price {
    font-size: 0.9rem;
    color: #6c757d;
    text-decoration: line-through;
    margin-right: 5px;
}

.discount {
    font-size: 0.9rem;
    color: #28a745;
}

.button-section {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.view-more-btn {
    font-size: 0.9rem;
    margin-right: 5px;
}

.add-to-cart-btn {
    font-size: 0.9rem;
    background-color: #E9272D !important;
}

.quantity-controls {
    display: flex;
    align-items: center;
}

.quantity-controls button {
    font-size: 1rem;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
}

.quantity {
    font-size: 1.1rem;
    margin: 0 10px;
}

