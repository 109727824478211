.order-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    padding: 20px;
    margin-top: 150px;

}
.order-container h3{
    font-weight: bold;
}
.box-shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
    border-radius: 8px;
    /* Optional: Adds rounded corners */
}