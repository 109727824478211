.section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.section-title h1 {
    font-size: 35px;
    font-weight: 700;
    color: #E9272D;
}

.section-line {
    width: 192px;
    height: 1px;
    background-color: #C4E454;
}

.hero-section {
    padding-top: 90px;
    background-color: #FFFFFF;
}

/* Add styles for the new <h2> */
.section-title h2 {
    font-size: 28px;
    /* Adjust the size as needed */
    font-weight: 600;
    /* You can choose any color */
    margin-bottom: 10px;
    /* Adds space between the new line and the main title */
}
@media (max-width:767px){
    .section-title h1{
        font-size: 23px;
    }
}