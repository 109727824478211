.privacy-policy-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
    margin-top: 150px;
    /* Full-screen height */
}

.privacy-policy-content {
    max-width: 900px;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
    color: #333;
}

.privacy-policy-content h5 {
    font-weight: bold;
}

.title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

h2 {
    margin-top: 20px;
    font-size: 20px;
}

ol {
    margin-left: 20px;
}

li {
    margin-bottom: 10px;
}

p {
    margin-bottom: 15px;
}

em {
    font-size: 0.9em;
    color: #666;
}